.conteinerRegistro{
    display: flex;
    justify-content: center;
}
.conteinerForm{

    padding: 20px;
    width: 40%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 20px;

}
.btnRegistro{
    background-color: var(--naranja);
    color: white;
}
.ojito{
    position: absolute;
      top: 70%;
      right:10px;
      transform: translateY(-50%);
      cursor: pointer
}