/* Transacciones.module.css o tu hoja de estilos */
.tableContainer {

  overflow-y: auto;
  overflow-x: auto;
}

.tabla {
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 10px;
}

.tabla th,
.tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: 160px;
  overflow-x: auto;
  font-size: 14px;
}

.tabla tbody tr:nth-child(even) {
  background-color: #f2f2f2;

}
.containerTrans {
  width: 1100px;
  margin-top: 20px;
}
/* search */

.formSection {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;
  justify-content: space-between;
}
.fechalabel {
  width: 400px;
}
.inputCVUalias {
  width: 400px;
}

.buscarAliasModal {
  margin-left: 20px;
  align-items: center;
  display: flex;
  justify-content:space-evenly;
}

.searchInput {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 400px;
  }
  .searchIcon {
    position: absolute;
    cursor: pointer;
    right: 15px; /* Ajusta la posición izquierda del icono */
    color: #e97a20; /* Ajusta el color del icono */
    font-size: 16px; /* Ajusta el tamaño del icono */
  }
  .dottedHr {
  
    border-style: dotted;
    border-color: #888; 
    border-width: 1px;
    margin-left: 20px ; 
  }
.modal{
  border: none !important;

margin-left: 320px;

}
.Overlay {

}