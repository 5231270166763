.containerNav{
  
    display: flex ;
    justify-content:space-between;
    width: 100vh;
    padding: 20px;
  
    box-shadow: rgba(242, 97, 13, 0.366) 0px 3px 12px;
}
.user{
    display: flex;
   
}
.logo_user{
    display:  flex;
   align-items: end;
   
}
.logo_user a{ width: 14rem; }

/* search */
.containerSearch{
 display: flex;
 justify-content: space-around;

}
.formularioSearch{
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.select {
    width: 200px;
   font-size: 14px !important;
}
.input{
    height: 35px !important;
    width: 200px;
}
button:hover{
    background-color: var(--naranja) !important;

    color: white !important;
}
.dottedHr {
  
    border-style: dotted;
    border-color: #888; 
    border-width: 1px;
    margin-left: 20px ; 
  }