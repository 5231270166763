/* sideBar.module.css */

.sidebar {
  width: 290px ;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #024267d4;
  color: white;
  z-index: 1;

}

.sidebarContent {
  flex: 1;

}

.footer {
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #12274ee3;
  color: white;
  text-align: center;
}

.logo {
  width: 120px;
}

.containerNav {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.logo_user {
  display: flex;
  align-items: center;
}

.user {
  display: flex;
  align-items: center;
  margin-right: 10px;
  text-decoration: none;
  padding-right: 5px;
  padding-left: 5px;
}

.boton .dropdown-menu {
  background-color: #343a40;
  color: white;
}

.boton .dropdown-item {
  color: white;
}

.dropdown-menu:hover{
  background-color: #343a40 !important;
}

.dropdown-item {
  color: white;

}

a:hover {
  color: #f7b681 !important;
  
}
.navLink.active {
  color: #f7b681 !important;

  font-weight: 600;
}
.dropdownMenu{
background-color: #04558569 !important;
 list-style: none;
}

.content {
  margin-left: 250px;
  padding: 20px;
  overflow-y: auto;
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .bodySide ul li a{
  
    font-size: 19px;
   
  }
}
@media only screen and (max-width: 650px) {
  .logo{
    width: 8rem;
  }
  .close{
    display: block;
  }
  .iconNav{
    display: block;
  }
  .contenedorSide{
    width: 40vh !important;
  }

}