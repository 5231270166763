.title {
  padding: 20px;
}
.containerList {
  padding: 20px !important;
}
.mas {
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  color: rgb(239, 116, 16) !important;
  margin-right: 5px;
  font-size: 15px !important;

  padding: 5px;
}

th {
  background-color: #063c62 !important;
  color: white !important;
  font-weight: initial !important;
}
.botonesTabla {
  border: 1px #063c62 !important;
}


.error_message {
  display: flex;
  justify-content: center;
  font-size: 22px;
}
/* admin */
.btndisabled{
    border-Radius:20% ;
     border:none ;
    background: none;
} 
.btndisabled:hover{
    background-Color:#e5ebed !important
}
table{
  box-shadow: rgba(149, 121, 107, 0.175) 2px 2px 8px !important;
}
.modal {
  position: relative;
  width: 80%; /* Ajusta el tamaño según sea necesario */
  max-height: 90vh; /* Limita la altura máxima del modal */
  overflow-y: auto; /* Permite que el contenido sea desplazable */
  margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-left: 300px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(252, 252, 252); /* Fondo oscuro detrás del modal */
}
/*  */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .containerList {
    font-size: 12px;
    width: 600px;
  }
  .mas {
    font-size: 11px !important;
  }
  .reverso{
    margin-left: 20% !important;
  }
 
}
@media only screen and (max-width: 767px) {
}
