
.container{
   
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.formLogin{
    margin-top: 10%;
    box-shadow: rgba(0, 0, 0, 0.216) 0px 0px 70px 4px;
    padding: 30px;
    width: 400px;
    border-radius: 10px;
}
.formLogin button{
    background-color: var(--naranja);
    float: right;
}

.ojito{
    position: absolute;
      top: 70%;
      right:10px;
      transform: translateY(-50%);
      cursor: pointer
}
.container_logo{
    display: flex;
    justify-content: center;
}