
.contenido{
  margin-left: 20%;

}
@media only screen and (min-width: 1810px) and (max-width: 2200px) {
  .contenido{
    margin-left:5%
  }

  }
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contenido{
    margin-left:18%
  }

  }

@media only screen and (min-width: 825px) and (max-width: 1400px) {
.contenedorPrincipal{

  margin-left: 8%;
}


}
