.baseTab{
  color:black !important;
    cursor: pointer;
}
.baseTab:hover{
 opacity: 0.7 !important;
 color: rgb(20, 19, 19) !important;
}
.activeTab{
    background-color: var(--naranja) !important;
    color: rgba(19, 17, 17, 0.905) !important ;
}
.nav-tabs .nav-item .nav-link {
width: 20rem;
}