
:root{
  --naranja: #063c62;
   font-size: 16px;
   font-family: "Rubik", sans-serif;
}
label{
  margin-right:5px ;
  margin-left:20px;
  font-size:14px
}

/* global */
input, select {
  height: 37px;

  font-size: 13px !important;
}
.w-100 .baseTab:hover{
  color:rgb(234, 238, 241) !important;
  background-color: rgb(5, 68, 117);
}
.w-100 .activeTab{
  color:rgb(247, 249, 250) !important;
  background-color: #063c62;
  
}
.dottedHr {
  
  border-style: dotted;
  border-color: #757373; 
  border-width: 1px;
  margin-left: 5px ; 
}
select option {
  font-size: 12px; /* Tamaño de fuente específico para las opciones (puedes ajustar según sea necesario) */
}
td, th {
  font-size: 13px !important;
}
b{
  font-size: 12px;
}